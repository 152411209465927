import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/eslint",
  "date": "2016-04-30",
  "title": "ESLINT",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "ESLint is a pattern identifying and reporting tool for JavaScript code. Somewhat it is similar to JSLint and JSHint with few differences."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Differences between `}<inlineCode parentName="p">{`JSLint`}</inlineCode>{` and `}<inlineCode parentName="p">{`JSHint`}</inlineCode>{`:`}</p>
    <p>{`ESLint uses:`}</p>
    <ul>
      <li parentName="ul">{`A (parser)`}{`[https://github.com/eslint/espree]`}{` called `}<inlineCode parentName="li">{`Espree`}</inlineCode>{` for parsing JavaScript.`}</li>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`AST`}</inlineCode>{` to evaluate patterns in code.`}</li>
    </ul>
    <Message type="info" title="AST" content="AST stands for Abstract Syntax Tree. Mozilla’s SpiderMonkey,Babel-ESLint and ECMAScript Esprima are two parsers for viewing abstract syntatic structure of the source code." mdxType="Message" />
    <p>{`ESLint does not sound as strict as it sounds. It is designed to be fully configurable, by turning off every rule and run only with basic syntax validation, or mix and match the bundled rules and your custom rules.`}</p>
    <h2>{`ESLint to define your own rules`}</h2>
    <p>{`The biggest feature of ESLint, is that you can develop the rules on your own. Or it is common to find an existing set of rules that come close to what you want, then customise from there.`}</p>
    <p>{`An example of this is `}<a parentName="p" {...{
        "href": "https://github.com/airbnb/javascript/tree/master/packages/eslint-config-airbnb"
      }}>{`eslint-config-airbnb`}</a>{` based on the `}<a parentName="p" {...{
        "href": "https://github.com/airbnb/javascript"
      }}>{`AirBnB JavaScript Style Guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "extends": "airbnb",
  "rules": {
    "default-case": 0,
    "max-len": [1, 120, 2, {ignoreComments: true}],
    "quotes": 0,
    "quote-props": [1, "consistent-as-needed"],
    "no-cond-assign": [2, "except-parens"],
    "no-else-return": 0,
    "no-param-reassign": 0,
    "no-unused-vars": [1, {"vars": "local", "args": "none"}],
    "space-infix-ops": 0
  }
}
`}</code></pre>
    <p>{`There are two primary ways to configure ESLint:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Configuration Comments`}</em>{` – JavaScript comments to embed configuration information directly into a file.`}</li>
      <li parentName="ul"><em parentName="li">{`Configuration Files`}</em>{` – A JSON or YAML file to specify configuration information for an entire directory and all of its subdirectories.`}</li>
    </ul>
    <h3>{`Configuration Files`}</h3>
    <p>{`This can be in the form of:`}</p>
    <ul>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`.eslintrc`}</inlineCode>{` file`}</li>
      <li parentName="ul">{`An \`eslintConfig field in a package.json file`}</li>
      <li parentName="ul">{`Specify a configuration file on the command line.`}</li>
    </ul>
    <p>{`First two options of above will be read by ESLint automatically`}</p>
    <p>{`There are several pieces of information that can be configured:`}</p>
    <ul>
      <li parentName="ul">{`Environments – which environments your script is designed to run in.`}</li>
      <li parentName="ul">{`Globals – the additional global variables your script accesses during execution.`}</li>
      <li parentName="ul">{`Rules – which rules are enabled and at what error level.`}</li>
    </ul>
    <p>{`All of these options give you fine-grained control over how ESLint treats your code.`}</p>
    <h2>{`Specifying Parser`}</h2>
    <p>{`By default, ESLint uses Espree as its parser. You can optionally specify that a different parser
should be used in your configuration file so long as the parser meets the following requirements:`}</p>
    <p>{`It must:`}</p>
    <ul>
      <li parentName="ul">{`Produce Esprima-compatible AST and token objects.`}</li>
      <li parentName="ul">{`Have an Esprima-compatible interface (it must export a parse() method).`}</li>
      <li parentName="ul">{`Be an npm module installed locally.`}</li>
    </ul>
    <p>{`Note that even with these compatibilities, there are no guarantees that an external parser will work correctly with ESLint and ESLint will not fix bugs related to incompatibilities with other parsers.`}</p>
    <p>{`To indicate the npm module to use as your parser, specify it using the parser option in your .eslintrc file. For example, the following specifies to use Esprima instead of Espree:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "parser": "esprima",
    "rules": {
        "semi": "error"
    }
}
`}</code></pre>
    <p>{`when using a custom parser, the `}<inlineCode parentName="p">{`parserOptions`}</inlineCode>{` configuration property is still required for ESLint to work properly with features not in ECMAScript 5 by default. Parsers are all passed parserOptions and may or may not use them to determine which features to enable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      